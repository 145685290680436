import React, { Component } from "react"
import { Link, navigate } from "gatsby"

class Header extends Component {

  state = {
    scrolled: false,
    offCanvas: false,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll)
    this.onScroll()
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll)
  }

  onScroll = () => {
    let currentScroll = document.documentElement.scrollTop || document.body.scrollTop
    let { scrolled } = this.state

    let nextState = currentScroll < window.innerHeight - document.querySelector('header').clientHeight
    if (nextState !== scrolled) this.setState({ scrolled: nextState })
  }

  _toggleOffCanvas = () => this.setState({ offCanvas: !this.state.offCanvas })

  _hideOffCanvas = () => this.setState({ offCanvas: false })

  render() {
    let { offCanvas, scrolled } = this.state

    let props = {
      onClick: this._hideOffCanvas,
    }

    return (
      <>
        <header className={`header ${offCanvas ? 'header--off-canvas' : ''} ${scrolled ? 'header--transparent' : ''}`}>
          <div className='header__inner'>
            <div className='header__logo__wrap'>
              <Link to="/" className='header__logo' onClick={(event) => {
                if (this.props.location.pathname === '/') {
                  event.preventDefault()
                  navigate('/about')
                }
                this._hideOffCanvas()
              }}>
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 302.4 141.2">
                  <g>
                    <path d="M0,31.6c1.3-0.3,2.7-0.5,4-0.6c1.3-0.1,2.8-0.1,4.4-0.1c2.7,0,5.4,0.3,8.2,0.9c0.3,0.5,0.6,1.2,0.8,2.1c0.2,0.9,0.5,1.9,0.7,3c0.2,1.1,0.4,2.1,0.6,3.1c0.1,1,0.3,1.9,0.4,2.5c0.9-1.5,1.9-3.1,3.2-4.5c1.2-1.5,2.8-2.8,4.5-4c1.8-1.2,3.8-2.1,6-2.8c2.2-0.7,4.7-1.1,7.4-1.1c4.1,0,8,0.8,11.6,2.3c3.6,1.5,6.8,3.9,9.4,7c2.7,3.1,4.8,7,6.3,11.8c1.5,4.7,2.2,10.3,2.2,16.7c0,6.3-0.9,11.9-2.7,16.8c-1.8,5-4.5,9.2-7.9,12.6c-3.5,3.5-7.7,6.1-12.7,8c-5,1.9-10.7,2.8-17,2.8c-1.6,0-3.2-0.1-4.8-0.2c-1.6-0.1-2.9-0.3-4-0.5v33.2c-1.8,0.3-3.6,0.5-5.3,0.6c-1.7,0.1-3.3,0.1-5,0.1c-1.6,0-3.3,0-5-0.1c-1.7-0.1-3.4-0.3-5.3-0.6V31.6z M20.5,90.4c2.6,0.8,5.6,1.2,8.9,1.2c6,0,10.5-1.9,13.7-5.8c3.2-3.8,4.8-9.7,4.8-17.5c0-3-0.2-5.7-0.7-8.3c-0.5-2.5-1.3-4.7-2.5-6.6c-1.2-1.8-2.6-3.3-4.4-4.3c-1.8-1.1-4-1.6-6.6-1.6c-2.4,0-4.4,0.5-6.1,1.4c-1.7,0.9-3.1,2.2-4.1,3.8c-1.1,1.6-1.8,3.4-2.3,5.6c-0.5,2.1-0.7,4.4-0.7,6.8V90.4z"/>
                    <path d="M100.2,75.3c0.4,5.8,2.4,10,6.1,12.5c3.7,2.6,8.5,3.9,14.4,3.9c3.3,0,6.6-0.3,10-0.9c3.4-0.6,6.7-1.5,9.7-2.7c1.1,1.9,2,4.2,2.7,6.9c0.8,2.7,1.2,5.6,1.3,8.7c-7.5,2.9-16,4.3-25.4,4.3c-6.9,0-12.9-1-17.8-2.9c-5-1.9-9-4.6-12.1-8c-3.1-3.4-5.4-7.5-6.9-12.2c-1.5-4.7-2.2-9.9-2.2-15.4c0-5.5,0.8-10.6,2.3-15.4c1.5-4.8,3.8-9,6.9-12.5c3-3.6,6.8-6.4,11.3-8.4c4.5-2.1,9.7-3.1,15.6-3.1c5.2,0,9.8,0.9,13.8,2.7c4,1.8,7.5,4.3,10.3,7.5c2.8,3.2,5,6.9,6.4,11.3c1.4,4.4,2.2,9.1,2.2,14.1c0,1.8-0.1,3.6-0.2,5.4c-0.1,1.8-0.3,3.2-0.5,4.3H100.2z M129.5,61c-0.2-4.6-1.5-8.3-3.8-11.2c-2.4-2.8-5.7-4.3-9.9-4.3c-4.8,0-8.5,1.3-11,4c-2.5,2.7-4,6.5-4.5,11.4H129.5z"/>
                    <path d="M200.5,57.3c0-2.3-0.4-4.2-1.2-5.6c-0.8-1.4-1.8-2.6-3.2-3.4c-1.3-0.8-3-1.4-5-1.7c-2-0.3-4.2-0.4-6.6-0.4c-5.2,0-10.7,1-16.6,3c-1.3-2.5-2.4-4.9-3-7.1c-0.7-2.2-1-4.9-1-7.9c4.2-1.4,8.5-2.5,12.7-3.2c4.2-0.7,8.2-1,11.8-1c10,0,17.8,2.4,23.4,7.2c5.6,4.8,8.4,12.5,8.4,23.1v43.3c-3.4,1.1-7.5,2.1-12.3,3c-4.8,1-10.2,1.4-16.3,1.4c-4.9,0-9.4-0.4-13.5-1.3c-4.1-0.9-7.6-2.3-10.5-4.3c-2.9-2-5.2-4.6-6.8-7.8c-1.6-3.2-2.4-7.1-2.4-11.7c0-4.6,1-8.5,3-11.6c2-3.1,4.5-5.6,7.6-7.5c3.1-1.9,6.6-3.2,10.5-4c3.8-0.8,7.7-1.2,11.5-1.2c2.8,0,5.9,0.1,9.2,0.4V57.3z M200.5,72.1c-1.2-0.2-2.4-0.4-3.8-0.5c-1.3-0.1-2.5-0.2-3.6-0.2c-4.7,0-8.4,0.8-11.2,2.5c-2.7,1.7-4.1,4.4-4.1,8.1c0,2.5,0.5,4.4,1.6,5.8c1.1,1.3,2.3,2.3,3.8,3c1.5,0.6,3.1,1,4.8,1.2c1.7,0.1,3.1,0.2,4.4,0.2c1.4,0,2.9-0.1,4.3-0.4c1.4-0.2,2.7-0.5,3.8-0.6V72.1z"/>
                    <path d="M235.5,0.9c3.3-0.6,6.6-0.9,10.1-0.9c3.5,0,6.9,0.3,10.2,0.9v105.4c-3.4,0.6-6.8,0.9-10.2,0.9c-3.5,0-6.8-0.3-10.1-0.9V0.9z M258.7,67.2l18.5-35.5c3.8-0.6,7.5-0.9,11.4-0.9c3.7,0,7.2,0.3,10.7,0.9l-19,34.5l22.2,40.1c-3.9,0.6-7.7,0.9-11.4,0.9c-3.4,0-6.9-0.3-10.7-0.9L258.7,67.2z"/>
                  </g>
                </svg>
              </Link>
            </div>
            <nav>
              <ul>
                <li><Link to='/about' {...props}>About</Link></li>
                <li><Link to='/services' {...props}>Services</Link></li>
                <li><Link to='/business-profile' {...props}>Business Profile</Link></li>
                <li><Link to='/news' {...props}>News</Link></li>
                <li><Link to='/contact' {...props}>Contact</Link></li>
              </ul>
            </nav>
            <div className={`hamburger ${offCanvas ? 'active' : ''}`} onClick={this._toggleOffCanvas}>
              <span className="lines"></span>
            </div>
          </div>
        </header>
        <div className={`off-canvas ${offCanvas ? 'off-canvas--active' : ''}`}>
          <nav className='container'>
            <ul className='col'>
              <li>1</li>
              <li>2</li>
              <li>3</li>
              <li>4</li>
              <li>5</li>
            </ul>
            <ul className='col col-75'>
              <li><Link to='/about' {...props}>About</Link></li>
              <li><Link to='/services' {...props}>Services</Link></li>
              <li><Link to='/business-profile' {...props}>Business Profile</Link></li>
              <li><Link to='/news' {...props}>News</Link></li>
              <li><Link to='/contact' {...props}>Contact</Link></li>
            </ul>
          </nav>
        </div>
      </>
    )
  }
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
