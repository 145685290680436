import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'

class Footer extends Component {
  render() {
    let { options } = this.props.data.settings

    return (
      <footer className='footer'>
        <div className='container'>
          <div className='col col-lrg-third'>
            <h3>Get in touch</h3>
          </div>
          <div className='col col-75 col-lrg-2thirds'>
            <div className='footer__split'>
              <p>
                © Peak Marketing & Communications<br />
                { options.address.split('\n').map((text, i) => <span key={i}>{ text }<br /></span>) }
              </p>
              <p>
                <a href={`mailto:${options.email}`}>{ options.email }</a><br />
                <a href={`tel:${options.phone}`}>{ options.phone }</a>
              </p>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        settings: wordpressAcfOptions(wordpress_id:{eq:"acf"}) {
          options {
            address
            email
            phone
          }
        }
      }
      `}
    render={data => <Footer data={data} {...props} />}
  />
)
